import { lighten, darken } from 'polished';
import { hexToRgba } from 'utils/hexToRgba';

const baseColors = {
  blue: '#272DBF',
  purple: '#7543E3',
  pink: '#fe9ffd',
  red: '#f06a4c',
  yellow: '#F8D800',
  green: '#00A45D',
  turq: '#29cccc',
};

const colorHueAmount = {
  fifty: 0.5,
  hundred: 0.4,
  twoHundred: 0.3,
  threeHundred: 0.2,
  fourHundred: 0.1,
  sixHundred: 0.1,
  sevenHundred: 0.2,
  eightHundred: 0.3,
  nineHundred: 0.4,
};

export default {
  blue50: lighten(colorHueAmount.fifty, baseColors.blue),
  blue100: lighten(colorHueAmount.hundred, baseColors.blue),
  blue200: lighten(colorHueAmount.twoHundred, baseColors.blue),
  blue300: lighten(colorHueAmount.threeHundred, baseColors.blue),
  blue400: lighten(colorHueAmount.fourHundred, baseColors.blue),
  blue500: baseColors.blue,
  blue600: darken(colorHueAmount.sixHundred, baseColors.blue),
  blue700: darken(colorHueAmount.sevenHundred, baseColors.blue),
  blue800: darken(colorHueAmount.eightHundred, baseColors.blue),
  blue900: darken(colorHueAmount.nineHundred, baseColors.blue),
  purple50: lighten(colorHueAmount.fifty, baseColors.purple),
  purple100: lighten(colorHueAmount.hundred, baseColors.purple),
  purple200: lighten(colorHueAmount.twoHundred, baseColors.purple),
  purple300: lighten(colorHueAmount.threeHundred, baseColors.purple),
  purple400: lighten(colorHueAmount.fourHundred, baseColors.purple),
  purple500: baseColors.purple,
  purple600: darken(colorHueAmount.sixHundred, baseColors.purple),
  purple700: darken(colorHueAmount.sevenHundred, baseColors.purple),
  purple800: darken(colorHueAmount.eightHundred, baseColors.purple),
  purple900: darken(colorHueAmount.nineHundred, baseColors.purple),
  pink50: lighten(colorHueAmount.fifty, baseColors.pink),
  pink100: lighten(colorHueAmount.hundred, baseColors.pink),
  pink200: lighten(colorHueAmount.twoHundred, baseColors.pink),
  pink300: lighten(colorHueAmount.threeHundred, baseColors.pink),
  pink400: lighten(colorHueAmount.fourHundred, baseColors.pink),
  pink500: baseColors.pink,
  pink600: darken(colorHueAmount.sixHundred, baseColors.pink),
  pink700: darken(colorHueAmount.sevenHundred, baseColors.pink),
  pink800: darken(colorHueAmount.eightHundred, baseColors.pink),
  pink900: darken(colorHueAmount.nineHundred, baseColors.pink),
  red25: hexToRgba(baseColors.red, 0.25),
  red50: hexToRgba(baseColors.red, 0.5),
  red75: hexToRgba(baseColors.red, 0.75),
  red100: lighten(colorHueAmount.hundred, baseColors.red),
  red200: lighten(colorHueAmount.twoHundred, baseColors.red),
  red300: lighten(colorHueAmount.threeHundred, baseColors.red),
  red400: lighten(colorHueAmount.fourHundred, baseColors.red),
  red500: baseColors.red,
  red600: darken(colorHueAmount.sixHundred, baseColors.red),
  red700: darken(colorHueAmount.sevenHundred, baseColors.red),
  red800: darken(colorHueAmount.eightHundred, baseColors.red),
  red900: darken(colorHueAmount.nineHundred, baseColors.red),
  yellow50: lighten(colorHueAmount.fifty, baseColors.yellow),
  yellow100: lighten(colorHueAmount.hundred, baseColors.yellow),
  yellow200: lighten(colorHueAmount.twoHundred, baseColors.yellow),
  yellow300: lighten(colorHueAmount.threeHundred, baseColors.yellow),
  yellow400: lighten(colorHueAmount.fourHundred, baseColors.yellow),
  yellow500: baseColors.yellow,
  yellow600: darken(colorHueAmount.sixHundred, baseColors.yellow),
  yellow700: darken(colorHueAmount.sevenHundred, baseColors.yellow),
  yellow800: darken(colorHueAmount.eightHundred, baseColors.yellow),
  yellow900: darken(colorHueAmount.nineHundred, baseColors.yellow),
  green50: lighten(colorHueAmount.fifty, baseColors.green),
  green100: lighten(colorHueAmount.hundred, baseColors.green),
  green200: lighten(colorHueAmount.twoHundred, baseColors.green),
  green300: lighten(colorHueAmount.threeHundred, baseColors.green),
  green400: lighten(colorHueAmount.fourHundred, baseColors.green),
  green500: baseColors.green,
  green600: darken(colorHueAmount.sixHundred, baseColors.green),
  green700: darken(colorHueAmount.sevenHundred, baseColors.green),
  green800: darken(colorHueAmount.eightHundred, baseColors.green),
  green900: darken(colorHueAmount.nineHundred, baseColors.green),
  turq50: lighten(colorHueAmount.fifty, baseColors.turq),
  turq100: lighten(colorHueAmount.hundred, baseColors.turq),
  turq200: lighten(colorHueAmount.twoHundred, baseColors.turq),
  turq300: lighten(colorHueAmount.threeHundred, baseColors.turq),
  turq400: lighten(colorHueAmount.fourHundred, baseColors.turq),
  turq500: baseColors.turq,
  turq600: darken(colorHueAmount.sixHundred, baseColors.turq),
  turq700: darken(colorHueAmount.sevenHundred, baseColors.turq),
  turq800: darken(colorHueAmount.eightHundred, baseColors.turq),
  turq900: darken(colorHueAmount.nineHundred, baseColors.turq),
  altWhite: '#F2F2F2',
  lightGray: '#E8E8E8',
  white: '#FFFFFF',
  white85: 'rgba(255, 255, 255, 0.85)',
  white75: 'rgba(255, 255, 255, 0.75)',
  white65: 'rgba(255, 255, 255, 0.65)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white25: 'rgba(255, 255, 255, 0.25)',
  black: '#000000',
  black75: 'rgba(0, 0, 0, 0.75)',
  black50: 'rgba(0, 0, 0, 0.55)',
  black25: 'rgba(0, 0, 0, 0.35)',
};
