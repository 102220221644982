import palette from './colors/palette';
import { lighten, darken } from 'polished';

const generateShades = (color: string) => ({
  base: color,
  lighter: lighten(0.05, color),
  lightest: lighten(0.1, color),
  darker: darken(0.05, color),
  darkest: darken(0.1, color),
});

export const sectionStyles = {
  primary: {
    bgBase: palette.altWhite,
    fgBase: palette.black,
    bg: `background: ${palette.altWhite};`,
    altBg: `background: ${palette.white};`,
    title: `color: ${palette.black};`,
    text: `color: ${palette.black};`,
    altText: `color: ${palette.black};`,
    accent: `color: ${palette.blue500};`,
    fg: `color: ${palette.black};`,
    primaryButtonBg: `background: ${palette.red500};`,
    primaryButtonText: `color: ${palette.white};`,
    primaryButtonFill: `fill: ${palette.red500};`,
  },
  secondary: {
    bgBase: palette.white,
    fgBase: palette.black,
    bg: `background: ${palette.white};`,
    altBg: `background: ${palette.altWhite};`,
    title: `color: ${palette.black};`,
    text: `color: ${palette.black};`,
    altText: `color: ${palette.black};`,
    accent: `color: ${palette.blue500};`,
    fg: `color: ${palette.black};`,
    primaryButtonBg: `background: ${palette.red500};`,
    primaryButtonText: `color: ${palette.white};`,
    primaryButtonFill: `fill: ${palette.red500};`,
  },
  tertiary: {
    bgBase: `linear-gradient(to right, ${palette.blue600}, ${palette.purple600})`,
    fgBase: palette.white,
    bg: `background: linear-gradient(to right, ${palette.blue600}, ${palette.purple600});`,
    altBg: `background: ${palette.altWhite};`,
    altText: `color: ${palette.black};`,
    title: `background: linear-gradient(to right, ${palette.pink500}, ${palette.red500});
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;`,
    text: `color: ${palette.white};`,
    accent: `background: linear-gradient(to right, ${palette.pink500}, ${palette.red500});
             -webkit-background-clip: text;
             -webkit-text-fill-color: transparent;`,
    fg: `background: linear-gradient(to right, ${palette.pink500}, ${palette.red500});
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;`,
    primaryButtonBg: `background: ${palette.red500};`,
    primaryButtonText: `color: ${palette.white};`,
    primaryButtonFill: `fill: ${palette.red500};`,
  },
  quaternary: {
    bgBase: `linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)`,
    fgBase: palette.white85,
    bg: `background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);`,
    altBg: `background: ${palette.white}`,
    title: `color: ${palette.white};`,
    altTitle: `color: ${palette.black};`,
    text: `color: ${palette.white85};`,
    altText: `color: ${palette.black75};`,
    accent: `color: ${palette.red500};`,
    fg: `color: ${palette.white};`,
    primaryButtonBg: `background: ${palette.blue400};`,
    primaryButtonText: `color: ${palette.white};`,
    primaryButtonFill: `fill: ${palette.blue400};`,
  },
  quintenary: {
    bgBase: palette.blue800,
    fgBase: palette.white85,
    bg: `background: ${palette.blue800};`,
    altBg: `background: ${palette.blue700}`,
    title: `color: ${palette.white};`,
    altTitle: `color: ${palette.white};`,
    text: `color: ${palette.white85};`,
    altText: `color: ${palette.white85};`,
    accent: `color: ${palette.red500};`,
    fg: `color: ${palette.white};`,
    primaryButtonBg: `background: ${palette.red500};`,
    primaryButtonText: `color: ${palette.white};`,
    primaryButtonFill: `fill: ${palette.red500};`,
  },
  senary: {
    bgBase: palette.purple800,
    fgBase: palette.white85,
    bg: `background: ${palette.purple800};`,
    altBg: `background: ${palette.purple700}`,
    title: `color: ${palette.white};`,
    altTitle: `color: ${palette.white};`,
    text: `color: ${palette.white85};`,
    altText: `color: ${palette.white85};`,
    accent: `color: ${palette.red500};`,
    fg: `color: ${palette.white};`,
    primaryButtonBg: `background: ${palette.red500};`,
    primaryButtonText: `color: ${palette.white};`,
    primaryButtonFill: `fill: ${palette.red500};`,
  },
  // Add other section types if necessary
};
