import palette from './palette';
import { lighten, darken } from 'polished';

const generateShades = (color: string) => ({
  base: color,
  lighter: lighten(0.05, color),
  lightest: lighten(0.1, color),
  darker: darken(0.05, color),
  darkest: darken(0.1, color),
});

export default {
  palette,
  baseColors: {
    base: palette.altWhite,
    base50: palette.white50,
    baseWhite: palette.altWhite,
    baseBlack: palette.black,
  },
  systemColors: {
    success: palette.green500,
    warrning: palette.yellow500,
    error: palette.red400,
    focus: palette.blue300,
  },
  ctaColors: {
    primary: palette.blue500,
    primaryHover: palette.blue400,
  },
  navigation: {
    background: palette.altWhite,
    backgroundAlt: palette.white,
    color: palette.black,
    colorAlt: palette.black,
  },
  backgrounds: {
    primary: generateShades(palette.blue600),
    fullWhite: palette.white,
    altWhite: palette.altWhite,
    offWhite: palette.lightGray,
  },
  typography: {
    black: palette.black,
    base: palette.black,
    medium: palette.black50,
    light: palette.black25,
    white: palette.white,
    white75: palette.white75,
    white50: palette.white50,
    white25: palette.white25,
    black75: palette.black75,
    black50: palette.black50,
    black25: palette.black25,
  },
  buttons: {
    nav: palette.purple700,
    navHover: palette.purple500,
    primary: {
      background: palette.blue600,
      hover: palette.blue400,
      disabled: palette.white50,
      color: palette.white,
    },
    tertiary: {
      background: palette.white,
      hover: palette.white50,
      disabled: palette.white,
      color: palette.blue600,
    },
  },
};
