import React from 'react';
import { ClerkProvider } from '@clerk/clerk-react';
import { deDE } from '@clerk/localizations';
import { PreviewStoreProvider } from 'gatsby-source-prismic';
import smoothscroll from 'smoothscroll-polyfill';
import { CookiesProvider } from 'react-cookie';
import './src/assets/styles/global.css';

const publishableKey = process.env.GATSBY_CLERK_PUBLISHABLE_KEY;

export function wrapRootElement({ element }) {
  return (
    <CookiesProvider>
      <PreviewStoreProvider initialEnabled>
        <ClerkProvider publishableKey={publishableKey} localization={deDE}>
          {element}
        </ClerkProvider>
      </PreviewStoreProvider>
    </CookiesProvider>
  );
}

export const onClientEntry = () => {
  smoothscroll.polyfill();
};
