export const textStyles = {
  // breakpoints: ['576px', '768px', '992px', '1200'],
  h1: {
    fontSize: ['2.25rem', '3rem', '3.75rem', '3.75rem'],
    lineHeight: ['1.2', '1.2', '1.2', '1.2'],
    letterSpacing: ['-0.36px', '-0.48px', '-0.6px', '-0.6px'],
    fontFamily: '"Gestura Headline"',
  },
  h2: {
    fontSize: ['2rem', '2.25rem', '3rem', '3rem'],
    lineHeight: ['1.2', '1.2', '1.2', '1.2'],
    letterSpacing: ['-0.32px', '-0.36px', '-0.48px', '-0.48px'],
    fontFamily: '"Gestura Headline"',
  },
  h3: {
    fontSize: ['2rem', '2rem', '2.25rem', '2.25rem'],
    lineHeight: ['1.2', '1.2', '1.2', '1.2'],
    letterSpacing: ['normal', 'normal', 'normal', 'normal'],
    fontFamily: '"Gestura Headline"',
  },
  h4: {
    fontSize: ['1.2rem', '1.5rem', '1.5rem', '1.5rem'],
    lineHeight: ['1.5', '1.5', '1.5', '1.5'],
    letterSpacing: ['normal', 'normal', 'normal', 'normal'],
    fontFamily: '"Gestura Text"',
    fontWeight: 'normal',
  },
  h5: {
    fontSize: ['1rem', '1rem', '1rem', '1rem'],
    lineHeight: ['1.4', '1.4', '1.4', '1.4'],
    letterSpacing: ['0.2px', '0.2px', '0.2px', '0.2px'],
    fontFamily: '"Basier Square"',
    fontWeight: '600',
  },
  h6: {
    fontSize: ['1rem', '1rem', '1rem', '1rem'],
    lineHeight: ['1.3', '1.3', '1.3', '1.3'],
    letterSpacing: ['normal', 'normal', 'normal', 'normal'],
    fontWeight: '500',
  },
  lead: {
    fontSize: ['1rem', '1.2 rem', '1.2rem', '1.2rem'],
    lineHeight: ['1.5', '1.5', '1.5', '1.5'],
    letterSpacing: ['normal', 'normal', 'normal', 'normal'],
    fontFamily: '"Basier Square"',
    fontWeight: 'normal',
  },
  body: {
    fontSize: ['1rem', '1rem', '1rem', '1rem'],
    lineHeight: ['1.4', '1.4', '1.4', '1.4'],
    fontFamily: '"Basier Square"',
    fontWeight: 'regular',
  },
  blog: {
    fontSize: ['1.125rem', '1.125rem', '1.125rem', '1.125rem'],
    lineHeight: ['1.5', '1.5', '1.5', '1.5'],
    letterSpacing: ['-0.2px', '-0.2px', '-0.2px', '-0.2px'],
    fontFamily: '"Gestura Text"',
    fontWeight: '400',
  },
  subline: {
    fontSize: ['0.875rem'],
    lineHeight: '1.4',
    fontWeight: 'regular',
  },
  overline: {
    fontSize: '0.875rem',
    lineHeight: '1.4',
    fontWeight: 'regular',
  },
  button: {
    fontSize: '1rem',
    lineHeight: '1.3',
    fontWeight: '500',
  },
};
